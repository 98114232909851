import React from "react";
import styled from "styled-components";
import ShadowCard from "./shadow-card";
import SubTitle from "./subtitle";
import ReservationBanner from "./reservation-banner";
import TimeTable from "./time-table";
import { ClosedDaysLabel } from "./closed-days-label";
import PayPay from "./paypay";
import { ExamArrivalDeadlineNotice } from "./ExamArrivalDeadlineNotice";

const Wrapper = styled.div`
  padding-bottom: 24px;
`;

const TimeTableWrapper = () => {
  return (
    <Wrapper>
      <ShadowCard>
        <SubTitle>診療時間</SubTitle>
        <TimeTable />
        <ExamArrivalDeadlineNotice />
        <ClosedDaysLabel />
        <SubTitle>Webによる順番予約サービス</SubTitle>
        <ReservationBanner />
        <div style={{ paddingTop: 24 }}>
          <PayPay />
        </div>
      </ShadowCard>
    </Wrapper>
  );
};

export default TimeTableWrapper;
